import { Text } from 'components';
import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface RingProps {
  duration: number;
}

const TextStyled = styled(Text)`
  color: #3fa134;
  text-align: center;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 4.5rem */
  letter-spacing: -0.033rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Svg = styled.svg`
  height: 200px;
  width: 200px;
`;

const OuterContainer = styled.div`
  height: 200px;
  width: 200px;
  position: relative;
`;

const Ring: FC<RingProps> = ({ duration = 6000 }) => {
  const [percentage, setPercentage] = useState(0);

  const cleanPercentage = (percentage: number) => {
    const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
    const isTooHigh = percentage > 100;
    return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
  };

  const Circle = ({
    percentage = 100,
    color,
  }: {
    percentage?: number;
    color: string;
  }) => {
    const r = 88;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - percentage) * circ) / 100;
    return (
      <circle
        r={r}
        cx={100}
        cy={100}
        fill="transparent"
        stroke={strokePct !== circ ? color : ''}
        strokeWidth={'0.75rem'}
        strokeDasharray={circ}
        strokeDashoffset={percentage ? strokePct : 0}
      />
    );
  };

  const Pie = ({ percentage }: { percentage: number }) => {
    const pct = cleanPercentage(percentage);
    return (
      <OuterContainer>
        <svg width={200} height={200}>
          <g transform={`rotate(-90 ${'100 100'})`} width={200} height={200}>
            <Circle color="#F6F4EE" />
            <Circle color={'#3FA134'} percentage={pct} />
          </g>
        </svg>
        <TextStyled>{percentage.toFixed(0)}%</TextStyled>
      </OuterContainer>
    );
  };
  useEffect(() => {
    const startTime = Date.now();

    const updateFill = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;

      if (elapsedTime < duration) {
        const newFillPercentage = (elapsedTime / duration) * 100;
        setPercentage(newFillPercentage);

        requestAnimationFrame(updateFill);
      } else {
        setPercentage(100);
      }
    };

    updateFill();

    return () => cancelAnimationFrame(updateFill);
  }, []);

  return <Pie percentage={percentage} />;
};

export default Ring;
